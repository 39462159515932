import React from "react";
import Home from "./pages/Home.jsx";


/*import Navbar from "./components/Navbar";
import Skills from './components/Skills';
import Hero2 from './components/Hero2';
import Prices from "./components/Prices";

import { BrowserRouter } from "react-router-dom";
import Hero from "./components/Hero";
import Footer from "./components/Footer";*/

import { Routes, Route } from "react-router-dom";
import Zasady from "./pages/Zasady.jsx";


function App() {
  return (
      <>
        <Routes>
         <Route path="/" element={<Home />} />
         <Route path="/privacy-policy" element={<Zasady />} />
        </Routes>
      </>
  );
}

export default App;
