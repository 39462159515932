import React from 'react';
import call from '../assets/call_yellow.png'; // Import your logo image
import '../index.css';

const Callbutton = () => {
  return (
    <button className='button-main button-small text-[#F6FB1A]'>
      <a href="tel:+420 608 097 250" className="flex items-center justify-center">
        <img 
          src={call} 
          alt="Volejte" 
          className="h-8 w-auto mr-2 transition-all duration-300 hover:opacity-75" 
        />
        <span className='p-2 j-font3 text-xl md:text-2xl'>608 097 250</span>
      </a>
    </button>
  );
}

export default Callbutton;
