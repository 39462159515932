import React from 'react'
import star from '../assets/star.png'
import '../index.css'

const Hero = () => {
  return (
    <div>
        <div className='flex flex-col items-center justify-center md:flex md:flex-row md:justify-around pb-5 bg-[#EDEDED]'>
            <div className='p-5 flex flex-col justify-center items-center flex-1 j-font3 text-2xl'>
                <img src={star} alt='' className="w-[75px] h-[75px] pb-2" />
                <p>Smontujeme, přiděláme, opravíme nebo vyměníme cokoliv, co bude potřeba.</p>
            </div>
            <div className='p-5 flex flex-col justify-center items-center flex-1 j-font3 text-2xl'>
                <img src={star} alt='' className="w-[75px] h-[75px] pb-2" />
                <p>Naši technici mohou vyrazit hned po zavolání, nebo domluvíme termín, který Vám bude vyhovovat.</p>
            </div>
            <div className=' p-5 flex flex-col justify-center items-center flex-1 j-font3 text-2xl'>
                <img src={star} alt='' className="w-[75px] h-[75px] pb-2" />
                <p>Řemeslné práce všeho druhu provádíme v Praze a okolí už od roku 2009. Spokojení zákazníci jsou naše nejlepší vizitka.</p>
            </div>
        </div>
    </div>
  )
}

export default Hero