import React from 'react'
import '../index.css'
import Callbutton from './Callbutton'



const Hero = () => {
    return (
        <section className='bg-img w-full h-full'>
            <div id="home" className='bg-img2 py-20'>
                <div className='flex flex-col items-start justify-center px-16 md:px-24'>
                    <div className="text-gray-100">
                        <h1 className="j-font text-4xl md:text-6xl">Hodinový manžel v Praze a okolí</h1>
                    </div>
                    <div className={`j-font2 text-2xl md:text-3xl mt-7 text-gray-100`}>
                        <p>Volejte v jakoukoliv hodinu, přijedeme do 30 minut<br>
                        </br> nebo se dohodneme na termínu.</p>
                    </div>
                    <div className="md:pl-20 pt-8">
                        <Callbutton />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero