import React from 'react'

const Prices = () => {
  return (
    <div id='Naše ceny' className="max-w-full mx-auto my-10 px-4 sm:max-w-screen-lg">
      <p className='p-6 j-font text-4xl text-center pb-6'>Ceník</p>
      <div className="overflow-x-auto">
        <table className="w-full divide-y divide-gray-200">
          <tbody className="bg-zinc-300 divide-y divide-gray-200">
            <tr>
              <td className="px-3 py-2 j-font3 text-lg">
                Výjezd a parkovné po Praze:
              </td>
              <td className="px-3 py-2 j-font3 text-lg">
                695,-
              </td>
            </tr>
            <tr>
            <td className="px-3 py-2 j-font3 text-lg">
              Výjezd mimo Prahu:
            </td>
            <td className="px-3 py-2 j-font3 text-lg">
              18kč/km
            </td>
          </tr>
          <tr>
            <td className="px-3 py-2 j-font3 text-lg">
              Hodinová sazba za technika :
            </td>
            <td className="px-3 py-2 j-font3 text-lg">
              od 500,-/ za hodinu
            </td>
          </tr>
          <tr>
            <td className="px-3 py-2 j-font3 text-lg">
              Při menších objednávkách cena dohodou
            </td>
            <td className="px-3 py-2 j-font3 text-lg">
            </td>
          </tr>
          <tr>
            <td className="px-3 py-2 j-font3 text-lg">
              Mimopracovní příplatek (17:00-22:00)
            </td>
            <td className="px-3 py-2 j-font3 text-lg">
              +50%
            </td>
          </tr>
          <tr>
            <td className="px-3 py-2 j-font3 text-lg">
              Noční příplatek (22:00-7:00)
            </td>
            <td className="px-3 py-2 j-font3 text-lg">
              +100%
            </td>
          </tr>
          <tr>
            <td className="px-3 py-2 j-font3 text-lg">
              Práce o víkendu ( sobota, neděle, státní svátky) 
            </td>
            <td className="px-3 py-2 j-font3 text-lg">
              +100%
            </td>
          </tr>
          <tr>
            <td className="px-3 py-2 j-font3 text-lg">
              Čištění odpadního potrubí 
            </td>
            <td className="px-3 py-2 j-font3 text-lg">
              843,-/bm
            </td>
          </tr>
            {/* Add more rows as needed */}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Prices
