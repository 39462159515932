import React from 'react'
import '../index.css'
import Callbutton from './Callbutton'

const Notfound = () => {
  return (
    <section className='bg-[#1A3364]'>
        <div className='flex flex-col justify-center items-center py-16 px-8'>
            <div>
              <h1 className='p-11 j-font text-white text-4xl'>Nenašli jste, co hledáte? Zavolejte nám a určitě najdeme řešení.</h1>
            </div>
            <div>
              <Callbutton />
            </div>
        </div>
    </section>
  )
}

export default Notfound