import React from "react";
import Navbar from "../components/Navbar";
import Skills from '../components/Skills';
import Hero2 from '../components/Hero2';
import Prices from "../components/Prices";

import Hero from "../components/Hero";
import Footer from "../components/Footer";
import Aboutus from "../components/Aboutus";
import Notfound from '../components/Notfound';
import Calldiv from "../components/Calldiv";



function Home() {
  return (
      <div>
        <Navbar />
        <Hero2 />
        <Hero />
        <Skills />
        <Notfound />
        <Prices />
        <Aboutus />
        <Calldiv />
        <Footer />
      </div>
  );
}

export default Home;
