import React from 'react';
import logo from '../assets/logo.png';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='py-2 bg-slate-600 flex flex-col lg:flex-row lg:justify-between'>
      <div className='flex flex-col pl-4 lg:pl-14 text-white pt-4 lg:pt-8'>
        <a href="mailto:domaproservis@gmail.com">
          <p className='j-font3 text-xl mb-2 lg:mb-0'>e-mail: domaproservis@gmail.com</p>
        </a>
        <a href="tel:+420 608 097 250">
          <p className='j-font3 text-xl mb-2 lg:mb-0'>telefon: 608 097 250</p>
        </a>
        <p className='j-font3 text-xl mb-2 lg:mb-0'>Adresa: Michalská 966/74, Praha 4</p>
        <Link to="/privacy-policy"><p className='j-font3 text-xl hover:text-blue-600'>Zásady ochrany osobních údajů</p></Link>
      </div>
      <div className='flex justify-center lg:justify-end px-4 lg:px-14 text-white'>
        <Link to='/' smooth><img src={logo} alt='' className='w-32 h-32'/></Link>
      </div>
    </div>
  );
};

export default Footer;
