import React from 'react';
import zednik from '../assets/zednik.jpg';
import elektrika from '../assets/elektrika.jpg';
import painting from '../assets/painting.jpg';
import instalater from '../assets/instalater.jpg';
import drevo from '../assets/drevo.jpg';
import manzel from '../assets/manzel.jpg';
import '../index.css';

const Skills = () => {
  return (
    <section id='Co umíme'>
      <div className='flex flex-col justify-center items-center bg-zinc-300 m-0'>
        <p className='j-font2 text-6xl pb-2 md:pb-6 pt-10 px-6 m-0'>Jsme odborníci v těchto oborech</p>
        <div className='flex flex-col md:flex md:flex-row pt-12 px-6 bg-zinc-300'>
          <div className='md:flex-1 flex flex-col justify-start items-center pb-10'>
            <img src={manzel} alt='' className='rounded-xl w-[350px] h-[250px] p-1 '/>
            <h1 className='j-font2 text-4xl py-6 text-center'>Hodinový manžel</h1>
            <ul className='flex flex-col justify-start px-14'>
              <li className='j-font3 text-2xl py-0.5'><span className="bullet">&#8226;</span> Opravy všeho druhu.</li>
              <li className='j-font3 text-2xl py-0.5'><span className="bullet">&#8226;</span> Montáže nábytku.</li>
              <li className='j-font3 text-2xl py-0.5'><span className="bullet">&#8226;</span> Zavěšení a přivrtání čehokoliv, co potřebujete.</li>
              <li className='j-font3 text-2xl py-0.5'><span className="bullet">&#8226;</span> Montáže kuchyňských linek.</li>
            </ul>
          </div>
          <div className='md:flex-1 flex flex-col justify-start items-center pb-10'>
            <img src={instalater} alt='' className='rounded-xl w-[350px] h-[250px] p-1 '/>
            <h1 className='j-font2 text-4xl py-6'>Instalatérské práce</h1>
            <ul className='flex flex-col justify-start px-14'>
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span> Čištění ucpané kanalizace</li>  
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span> Rozvody vody a odpadu</li>
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span> Opravy a výměny WC</li>
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span> Výměny vodovodních baterií</li>
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span> Připojení myčky na nádobí</li>
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span> připojení pračky/ sušičky</li>
            </ul>
          </div>
          <div className='md:flex-1 flex flex-col justify-start items-center pb-10'>
            <img src={painting} alt='' className='rounded-xl w-[350px] h-[250px] p-1'/>
            <h1 className='j-font2 text-4xl py-6 text-center'>Malířské práce a natěračské práce</h1>
            <ul className='flex flex-col justify-start px-14'>
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span> Malování pokojů a bytů</li>
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span> Natírání dřevěných i železných konstrukcí</li>
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span> Patinování dřeva</li>
            </ul>
          </div>
        </div>
        <div className='flex flex-col md:flex md:flex-row py-12 px-6'>
          <div className='md:flex-1 flex flex-col justify-start items-center pb-2'>
            <img src={elektrika} alt='' className='rounded-xl w-[350px] h-[250px] p-1'/>
            <h1 className='j-font2 text-4xl py-6 text-center'>Elektrikářské práce</h1>
            <ul className='flex flex-col justify-start px-14'>
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span> Instalace zásuvek a vypínačů</li>
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span> Montáže a opravy světel a lustrů</li>
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span> Drobné elektrikářské práce a opravy</li>
            </ul>
          </div>
          <div className='md:flex-1 flex flex-col justify-start items-center pb-10'>
            <img src={zednik} alt='' className='rounded-xl w-[350px] h-[250px] p-1'/>
            <h1 className='j-font2 text-4xl py-6 text-center'>Zednické práce</h1>
            <ul className='flex flex-col justify-start px-14'>
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span> Částečná i kompletní rekonstrukce bytů</li>
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span> Vyzdívání příček</li>
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span> Omítání stěn (i malé opravy)</li>
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span> Výkopové a bourací práce</li>
            </ul>
          </div>
          <div className='md:flex-1 flex flex-col justify-start items-center pb-2'>
            <img src={drevo} alt='' className='rounded-xl w-[350px] h-[250px] p-1'/>
            <h1 className='j-font2 text-4xl py-6 text-start'>Truhlářské práce</h1>
            <ul className='flex flex-col justify-start px-14'>
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span> Nábytek na míru</li>
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span> Montáže nábytku</li>
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span> Montáže kuchyňských linek</li>
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span> Opravy dřevěných konstrukcí</li>
              <li className='j-font3 text-2xl'><span className="bullet">&#8226;</span> Výměny a opravy prahů a dveří</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Skills;
